<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据中心</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">线上考试统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-input
                v-model="searchForm.compName"
                clearable
                placeholder="请输入机构名称"
                size="small"
              ></el-input>
            </div>
            <div title="培训工种" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">培训工种:</span>
              <el-input
                v-model="searchForm.occupationName"
                clearable
                placeholder="请输入培训工种"
                size="small"
              ></el-input>
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">机构名称:</span>
              <el-input
                v-model="searchForm.examinationName"
                clearable
                placeholder="请输入机构名称"
                size="small"
              ></el-input>
            </div>
            <div title="培训科目" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">培训科目:</span>
              <el-select
                v-model="searchForm.subjectCode"
                clearable
                size="small"
                placeholder="请选择结算单类型"
              >
                <el-option
                  v-for="item in subjectCodeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="考试日期" class="searchboxItem ci-full">
              <span class="itemLabel">考试日期:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="searchForm.times"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>

            <div class="df" style="padding-left: 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                width="200px"
              />
              <el-table-column
                label="考试名称"
                align="left"
                show-overflow-tooltip
                prop="examinationName"
                width="200px"
              />
              <el-table-column
                label="考试类型"
                align="left"
                show-overflow-tooltip
                prop="examType"
                width="100px"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary("EXAMINATION_EXAM_TYPE", scope.row.examType)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="培训工种"
                align="left"
                show-overflow-tooltip
                prop="occupationName"
                width="150px"
              />
              <el-table-column
                label="培训等级"
                align="left"
                show-overflow-tooltip
                prop="levelCode"
                width="120px"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("ET_TRAIN_LEVEL", scope.row.levelCode) }}
                </template>
              </el-table-column>
              <el-table-column
                label="考试科目"
                align="left"
                show-overflow-tooltip
                prop="subjectCode"
                width="100px"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "EA_EXAM_SUBJECT_CODE",
                      scope.row.subjectCode
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column label="考试时间" align="left" width="140px">
                <template slot-scope="scope">
                  {{ scope.row.startTime }} - {{ scope.row.endTime }}
                </template>
              </el-table-column>
              <el-table-column
                label="考试人数"
                align="left"
                show-overflow-tooltip
                prop="userNum"
                width="100px"
              />
              <el-table-column
                label="试卷名称"
                align="left"
                show-overflow-tooltip
                prop="paperName"
                width="300px"
              />
              <el-table-column
                label="考试状态"
                align="left"
                show-overflow-tooltip
                prop="examState"
                width="120px"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "EXAMINATION_EXAM_STATE",
                      scope.row.examState
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="阅卷状态"
                align="left"
                show-overflow-tooltip
                prop="scoringState"
                width="120px"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "EXAMINATION_SCORING_STATE",
                      scope.row.scoringState
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="监考老师"
                align="left"
                show-overflow-tooltip
                prop="invigilationTeacher"
                width="120px"
              />
              <el-table-column
                label="阅卷老师"
                align="left"
                show-overflow-tooltip
                prop="markTeacher"
                width="120px"
              />
              <el-table-column
                label="操作"
                align="center"
                width="160px"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="seeInfo(scope.row, scope.row.id)"
                    >查看详情</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="seePaper(scope.row, scope.row.id)"
                    >查看试卷</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="seeStudent(scope.row, scope.row.id)"
                    >查看学员</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="seeExamParameters(scope.row, scope.row.id)"
                    >考试参数</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "workOrder_onlineExamStatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      searchForm: {
        compName: "", // 机构名称
        occupationName: "", // 培训工种
        examinationName: "", // 机构名称
        subjectCode: "", // 考试科目
        times: "", // 考试时间
      },
      // 考试科目 - 下拉数据
      subjectCodeList: [],
    };
  },
  watch: {},
  created() {
    this.getInvoice_type();
  },
  computed: {},
  mounted() {},
  methods: {
    // 获取 - 获取字典
    getInvoice_type() {
      // 培训等级
      let arr1 = this.$setDictionary("EA_EXAM_SUBJECT_CODE", "list");
      for (const key in arr1) {
        this.subjectCodeList.push({
          label: arr1[key],
          value: key,
        });
      }
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchForm.compName) {
        params.compName = this.searchForm.compName;
      }
      if (this.searchForm.occupationName) {
        params.occupationName = this.searchForm.occupationName;
      }
      if (this.searchForm.examinationName) {
        params.examinationName = this.searchForm.examinationName;
      }
      if (this.searchForm.subjectCode) {
        params.subjectCode = this.searchForm.subjectCode;
      }
      if (this.searchForm.times) {
        params.startDate = this.searchForm.times[0];
        params.endDate = this.searchForm.times[1];
      }
      this.doFetch(
        {
          url: "/run/examination/page",
          params,
          pageNum,
        },
        true,
        6
      );
    },
    // 查看详情
    seeInfo(row) {
      this.$router.push({
        path: "/web/examination/onlineTest/onlineTestListAddEdit",
        query: {
          examinationId: row?.examinationId ?? "",
          examState: "30", // 先默认30，只能查看
        },
      });
    },
    // 查看试卷
    seePaper(row) {
      this.$router.push({
        path: "/web/examination/onlineTest/examinationPaperList",
        query: {
          examinationId: row.examinationId, // 考试id
          examinationName: row.examinationName, // 考试名称
          subjectCode: row.subjectCode, // 考试科目
          noDelete: "20", // 前端所需字段，是否显示删除按钮:10或空-显示;20-不显示
        },
      });
    },
    // 查看学员
    seeStudent(row) {
      console.log(row.examinationId)
      this.$router.push({
        path: "/web/workOrder/onlineExamStatistics_seeStudent",
        query: {
          examinationId: row.examinationId, // 考试id
        },
      });
    },
    // 考试参数
    seeExamParameters(row) {
      this.$router.push({
        path: "/web/examination/onlineTest/examinationConfig",
        query: {
          examinationId: row.examinationId, // 考试id
        },
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 通过 `vm` 访问组件实例
      if (
        [
          "/web/examination/onlineTest/onlineTestListAddEdit",
          "/web/examination/onlineTest/examinationPaperList",
          "/web/workOrder/onlineExamStatistics_seeStudent",
          "/web/examination/onlineTest/examinationConfig",
        ].includes(from.path)
      ) {
        vm.getData(vm.pageNum);
      }
    });
  },
};
</script>
<style lang="less" scoped></style>
